export const MenuRoutes = [
    {
        title:'Home',
        path:'/'
    }, 
    {
        title:'Ecosystem',
        path:'/ecosystem',
        elements:[ 
            {title:'ZenoDigiArt (ERC721)',path:'https://www.zenodigiart.com/#/collectionsv2', outLink:true},
            {title:'ZenoBundle (ERC1155)',path:'https://www.zenobundle.com/#/collectionsv2', outLink:true},
            {title:'More...',path:'/comming'} 
        ]
    }, 
    {
        title:'ZenoPASS',
        path:'/membershipspagev2',
        // elements:[
        //     {title:'ZenoPASS',path:'/membershipspagev2'},
        //     {title:'My whitelists',path:'/mywhitelistspagev2'},
        //     {title:'Create Colletion',path:'/createcolletionv2'},
        //     {title:'Create NFT',path:'/createnftv2'}, 
        // ]
    }, 
    {
        title:'About Us',
        path:'/aboutus' 
    }
]
